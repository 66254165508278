import {
    FilterContextProvider,
    useCompanyFavouriteFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Add } from "@mui/icons-material";
import TableViewIcon from "@mui/icons-material/TableView";
import { Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { useCurrentUserIdOrThrow } from "@/hooks/useCurrentUser";
import { useManyAggregatedViews } from "@/generated/AggregatedViewEntity";
import { DataTable, useGetDataTableElasticFieldsQuery } from "@/generated/client";
import {
    mapDataTableToFilterDataSource,
    mapFromAnalysisFilterToFrontendFilters,
    useElasticFieldsForFilterContextProvider,
} from "@/helpers";
import CreateAggregatedView from "./CreateAggregatedView";
import DeleteAggregatedViewConfirmationDialog from "./DeleteAggregatedViewConfirmation";
import RefreshAggregatedView from "./RefreshAggregatedViewConfiguration";
import { UpdateAggregatedViewModal } from "./UpdateAggregatedView";
import { useAggregatedTablesOfDataTable } from "./hooks";
import messages from "./messages";

interface Props {
    dataTableId: string;
    dataTableName: string;
    dataTable: DataTable;
    selectedDataTableView?: DataTable;
    onDataTableViewChange: (dataTableId: string) => void;
}

const CreateAggregatedViewButton: React.FC<Props> = ({
    dataTableId,
    dataTableName,
    dataTable,
    selectedDataTableView,
    onDataTableViewChange,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const userId = useCurrentUserIdOrThrow();

    const filterDataSource = mapDataTableToFilterDataSource(dataTable);

    const { result } = useGetDataTableElasticFieldsQuery({ input: { dataTableId, withEnrichments: false } });

    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    const mainTableOption = useMemo(
        () => ({
            label: dataTableName,
            value: dataTableId,
        }),
        [dataTableId, dataTableName]
    );
    const otherViews = useAggregatedTablesOfDataTable(dataTableId).map((dt) => ({
        label: dt.name,
        value: dt.id,
    }));
    const options = [mainTableOption, ...otherViews];
    const selectedAggregatedView = useManyAggregatedViews().data?.find(
        (aggregatedView) => aggregatedView.resultDataTableId === selectedDataTableView?.id
    );
    useEffect(() => {
        if (dataTableId !== selectedDataTableView?.id && !selectedAggregatedView) {
            onDataTableViewChange(dataTableId);
        }
    }, [dataTableId, selectedAggregatedView, selectedDataTableView?.id, onDataTableViewChange]);
    return (
        <Stack flexGrow={1} direction="row" alignItems="flex-end">
            <Autocomplete
                fullWidth
                options={options}
                sx={{ width: "280px" }}
                defaultValue={mainTableOption}
                value={options.find((o) => o.value === selectedDataTableView?.id) ?? null}
                onChange={(_, value) => {
                    value && onDataTableViewChange(value.value);
                    track("Data table view: Selected Aggregated table");
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        InputProps={{ ...params.InputProps, startAdornment: <TableViewIcon /> }}
                        label={fm(messages.tableViewSelection)}
                    />
                )}
            />
            <Tooltip title={fm(messages.createAggregatedViewTooltipText)}>
                <IconButton onClick={() => setIsModalOpen(true)}>
                    <Add />
                </IconButton>
            </Tooltip>
            {selectedAggregatedView && selectedDataTableView && (
                <>
                    <DeleteAggregatedViewConfirmationDialog dataTableId={selectedAggregatedView.resultDataTableId} />
                    <RefreshAggregatedView aggregatedViewId={selectedAggregatedView.id} />

                    <GraphqlRequestContainer asyncData={result}>
                        {({ elasticFields }) => (
                            <FilterContextProvider
                                inherit={false}
                                initialFilters={selectedAggregatedView.filters
                                    .map(mapFromAnalysisFilterToFrontendFilters)
                                    .filter(isNotNullish)}
                                dataSource={filterDataSource}
                                disableDefaultCombination
                                useDataFields={useElasticFieldsForFilterContextProvider}
                                // hooks for favourite filters
                                usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                            >
                                <UpdateAggregatedViewModal
                                    aggregatedView={selectedAggregatedView}
                                    resultDataTable={selectedDataTableView}
                                    selectableFields={elasticFields}
                                    userId={userId}
                                    dataTable={dataTable}
                                />
                            </FilterContextProvider>
                        )}
                    </GraphqlRequestContainer>
                </>
            )}
            <Dialog maxWidth="md" fullWidth onClose={() => setIsModalOpen(false)} open={isModalOpen} disablePortal>
                <DialogTitle>{fm(messages.title, { dataTableName })}</DialogTitle>
                <DialogContent>
                    <GraphqlRequestContainer asyncData={result}>
                        {({ elasticFields }) => (
                            <FilterContextProvider
                                inherit={false}
                                dataSource={filterDataSource}
                                disableDefaultCombination
                                useDataFields={useElasticFieldsForFilterContextProvider}
                                // hooks for favourite filters
                                usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                            >
                                <CreateAggregatedView
                                    dataTableId={dataTableId}
                                    dataTable={dataTable}
                                    selectableFields={elasticFields}
                                    userId={userId}
                                    onCancelClick={() => setIsModalOpen(false)}
                                />
                            </FilterContextProvider>
                        )}
                    </GraphqlRequestContainer>
                </DialogContent>
            </Dialog>
        </Stack>
    );
};

export default CreateAggregatedViewButton;
