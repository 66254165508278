import React from "react";
import "core-js/features/array/at";
import "core-js/features/array/flat";
import { createRoot } from "react-dom/client";
import App from "./App";
import { setupProxyAuth } from "./setupProxyAuth";

let root: ReturnType<typeof createRoot> | null = null;

window.renderDataTables = (containerId, props) => {
    const node = document.getElementById(containerId);
    if (node) {
        root = createRoot(node);
        root.render(
            <App
                locale={props.locale}
                theme={props.theme}
                sessionContext={props.sessionContext}
                history={props.history}
            />
        );
    }
};

window.unmountDataTables = () => {
    if (root) {
        root.unmount();
    }
};

async function devSetup() {
    const node = document.getElementById("root");
    if (node) {
        let sessionContext = {
            id: process.env.REACT_APP_DEFAULT_USERID ?? "",
            tenant: process.env.REACT_APP_DEFAULT_TENANT ?? "",
            email: process.env.REACT_APP_DEFAULT_EMAIL ?? "",
        };
        if (process.env.REACT_APP_PROXY_TO_PRODUCTION) {
            sessionContext = await setupProxyAuth();
        }
        root = createRoot(node);
        root.render(<App sessionContext={sessionContext} />);
    }
}

if (!document.getElementById("DataTables-container") && process.env.NODE_ENV === "development") {
    devSetup();
}
