import {
    FilterContextProvider,
    useCompanyFavouriteFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Stack } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { useAggregatedTablesOfDataTable } from "@/components/AggregatedView/hooks";
import ContentCard from "@/components/ContentCard";
import { DirectExportDownload } from "@/components/DirectExportDownload";
import DataTableOverviewMenu from "@/components/Menu";
import ChangedConnectionsContextProvider from "@/containers/CubeBuilderPage/context";
import { TableViewV2 } from "@/containers/TableView/TableViewV2";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import { HiddenColumnsContextProvider } from "@/contexts/hiddenColumnsContext";
import { useDataTable } from "@/generated/DataTableEntity";
import { mapDataTableToFilterDataSource, useElasticFieldsForFilterContextProvider } from "@/helpers";
import globalMessages from "@/messages";
import messages from "./messages";
import { useCurrentUserIdOrThrow } from "@/hooks/useCurrentUser";

const CubeBuilderPage = React.lazy(() => import("@/containers/CubeBuilderPage"));
const PipelineV2Page = React.lazy(() => import("@/containers/PipelinePageV2"));
const ManageDataTablePage = React.lazy(() => import("@/containers/Settings/ManageDataTablePage"));

const DataTableOverview: React.FC<{ dataTableId: string }> = ({ dataTableId }) => {
    const basePath = useBasePath();
    const location = useLocation();
    const userId = useCurrentUserIdOrThrow();
    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true);
    const [selectedTableId, setSelectedTableId] = useState<string>(dataTableId);
    const table = useDataTable(dataTableId);
    const tableViews = useAggregatedTablesOfDataTable(dataTableId);
    useEffect(() => {
        if (table.data) {
            const data = {
                source: "ignite-breadcrumbs",
                payload: [{ text: fm(messages.dataTablesMenuHeader).toString() }, { text: table.data.name }],
            };
            window.postMessage(data, window.location.origin);
        }
    }, [table]);

    const filterDataSource = mapDataTableToFilterDataSource(
        selectedTableId === table.data?.id ? table.data : tableViews.find((tv) => tv.id === selectedTableId)
    );
    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters(userId, process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(process.env.REACT_APP_GRAPHQL_ROUTER_URL as string);
    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        userId,
        process.env.REACT_APP_GRAPHQL_ROUTER_URL as string
    );

    useEffect(() => {
        setSelectedTableId(dataTableId);
    }, [dataTableId]);

    const tabLinks = [
        {
            path: `${basePath}${dataTableId}/table-view`,
            name: fm(messages.tableViewTab).toString(),
            active: location.pathname.includes("table-view"),
        },
        {
            path: `${basePath}${dataTableId}/connected-pipeline`,
            name: fm(messages.pipelineTab).toString(),
            active: location.pathname.includes("connected-pipeline"),
        },
        {
            path: `${basePath}${dataTableId}/data-cube-builder`,
            name: fm(messages.connections).toString(),
            active: location.pathname.includes("data-cube-builder"),
        },
        {
            path: `${basePath}${dataTableId}/settings`,
            name: fm(globalMessages.settings).toString(),
            active: location.pathname.includes("settings"),
        },
    ];

    const toggleMenu = () => setSideMenuOpen((prev) => !prev);

    return (
        <Stack paddingRight={6} direction="row" maxHeight="calc(100vh - 65px)">
            <DataTableOverviewMenu sideMenuOpen={sideMenuOpen} onMenuToggle={toggleMenu} />
            <Stack flexGrow={1}>
                <GraphqlRequestContainer asyncData={table} loading={<ContentCard tabLinks={[]} loading />}>
                    {(dataTable) => (
                        <ContentCard tabLinks={tabLinks}>
                            <HiddenColumnsContextProvider userId={userId} dataTableId={dataTableId}>
                                <Switch>
                                    <Route path="*/:dataTableId/connected-pipeline">
                                        <Suspense>
                                            <PipelineV2Page dataTable={dataTable} />
                                        </Suspense>
                                    </Route>
                                    <Route path="*/settings">
                                        <Suspense>
                                            <ManageDataTablePage dataTable={dataTable} />
                                        </Suspense>
                                    </Route>
                                    <Route path="*/exports/:exportId">
                                        <DirectExportDownload />
                                    </Route>
                                    <Route path={["*/data-cube-builder"]}>
                                        <ChangedConnectionsContextProvider>
                                            <Suspense>
                                                <CubeBuilderPage dataTable={dataTable} />
                                            </Suspense>
                                        </ChangedConnectionsContextProvider>
                                    </Route>
                                    <Route path={["*/table-view", "*/"]}>
                                        <FilterContextProvider
                                            inherit={false}
                                            dataSource={filterDataSource}
                                            useDataFields={useElasticFieldsForFilterContextProvider}
                                            disableDefaultCombination
                                            usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                                            upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                                            useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                                        >
                                            {selectedTableId && (
                                                <TableViewV2
                                                    dataTable={dataTable}
                                                    setSelectedDataTableViewId={setSelectedTableId}
                                                    selectedDataTableViewId={selectedTableId}
                                                />
                                            )}
                                        </FilterContextProvider>
                                    </Route>
                                </Switch>
                            </HiddenColumnsContextProvider>
                        </ContentCard>
                    )}
                </GraphqlRequestContainer>
            </Stack>
        </Stack>
    );
};

export default DataTableOverview;
